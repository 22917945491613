import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Row, Col } from 'reactstrap';
import _ from 'lodash';
import { useLocation } from 'react-router';
import ReactNotificationAlert from 'react-notification-alert';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import * as moment from 'moment';

function Header({
  handleOpenFilter,
  showBtnFilter = false,
  showBtnExport = false,
  handleExport,
  isDisable = true,
  isExport = false,
  filterValues = {},
  screen = 'list',
}) {
  const notificationAlertRef = useRef();
  const search = useLocation().search;
  const orderName = new URLSearchParams(search).get('orderName');
  const producerName = new URLSearchParams(search).get('producerName');
  const producerCode = new URLSearchParams(search).get('producerCode');
  
  return (
    <>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="header header-dark bg-primary pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-end py-4">
              {screen === 'list' ? (
                <Col md="8">
                  {Object.values(filterValues ?? {}).every((value) =>
                    _.isEmpty(value)
                  ) ? (
                    <></>
                  ) : (
                    <>
                      <span className="font-weight-600 text-info">
                        Đang lọc theo :
                      </span>

                      {filterValues.orderId && (
                        <span className="font-weight-600 text-info">
                          &ensp;Đơn hàng: {filterValues.orderId.label};
                        </span>
                      )}
                      {filterValues?.productionCommandId?.label && (
                        <span className="font-weight-600 text-info">
                          &ensp;Lệnh sản xuất:{' '}
                          {filterValues?.productionCommandId?.label};
                        </span>
                      )}
                      {!_.isEmpty(filterValues.producerProductionIds) && (
                        <span className="font-weight-600 text-info">
                          &ensp;Nhà gia công:{' '}
                          {filterValues.producerProductionIds.map(
                            (item, index) =>
                              index ===
                              filterValues.producerProductionIds.length - 1
                                ? item.label
                                : `${item.label}, `
                          )}
                          ;
                        </span>
                      )}
                      {filterValues.customerOrgId && (
                        <span className="font-weight-600 text-info">
                          &ensp;Chi nhánh: {filterValues.customerOrgId.label};
                        </span>
                      )}
                      {filterValues.productId && (
                        <span className="font-weight-600 text-info">
                          &ensp;Sản phẩm: {filterValues.productId.label};
                        </span>
                      )}
                      {filterValues.fromDate && (
                        <span className="font-weight-600 text-info">
                          &ensp;Từ ngày:{' '}
                          {moment(filterValues.fromDate).format('DD/MM/YYYY')};
                        </span>
                      )}
                      {filterValues.toDate && (
                        <span className="font-weight-600 text-info">
                          &ensp;Đến ngày:{' '}
                          {moment(filterValues.toDate).format('DD/MM/YYYY')};
                        </span>
                      )}
                      {filterValues.year && (
                        <span className="font-weight-600 text-info">
                          &ensp;Năm: {filterValues.year.value};
                        </span>
                      )}
                    </>
                  )}
                </Col>
              ) : (
                <Col md="8">
                  <p className="font-weight-600 text-info mb-0">
                    &ensp;Đơn hàng: {orderName};
                  </p>
                  <p className="font-weight-600 text-info mb-0">
                    &ensp;Đơn vị gia công: {producerCode} - {producerName};
                  </p>
                </Col>
              )}

              <Col className="mt-3 mt-md-0 text-md-right pl-0" md="4">
                {showBtnExport && (
                  <LoadingButtonCustom
                    className="btn-md text-sm ml-0 text-primary btn-neutral"
                    disabled={isDisable}
                    onClick={handleExport}
                    loading={isExport}
                    outline={false}
                  >
                    Xuất danh sách
                  </LoadingButtonCustom>
                )}
                {showBtnFilter && (
                  <Button
                    onClick={handleOpenFilter}
                    className="btn-neutral"
                    color="default"
                    size="md"
                  >
                    Lọc hiển thị
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

Header.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default Header;
