import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  Row,
  Table,
} from 'reactstrap';
import InputCustom from 'views/pages/components/InputCustom';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import queryString from 'query-string';
import Error from 'views/pages/components/Error';
import RequireCustom from 'views/pages/components/RequireCustom';
import { orderPlanActions } from 'Redux/Actions';
import moment from 'moment';
import _ from 'lodash';
import { nplOrderActions, exportActions } from 'Redux/Actions';
import { notify } from 'common';
import constant from 'constant';
import FileDownload from 'js-file-download';
import DatePicker from 'reactstrap-date-picker';

const FormNplOrder = ({
  open,
  setFormModal,
  isFormAdd,
  nplOrder,
  notificationAlertRef,
  handleGetNplOrders,
  orderValue
}) => {
  const dispatch = useDispatch();

  const nplOrderSchemaCreate = yup.object().shape({
    code: yup.string().required('Mã đề xuất không được để trống!'),
    name: yup.string().required('Tên đề xuất không được để trống!'),
    orderPlanId: yup.string().required('Vui lòng chọn kế hoạch sản xuất!'),
  });
  const nplOrderSchemaUpdate = yup.object().shape({
    code: yup.string().required('Mã đề xuất không được để trống!'),
    name: yup.string().required('Tên đề xuất không được để trống!'),
    orderPlanId: yup.string().required('Vui lòng chọn kế hoạch sản xuất!'),
  });

  const [nplOrderInfo, setNplOrderInfo] = useState({
    code: '',
    name: '',
    suggestDate: new Date().toISOString(),
    orderPlanId: '',
    totalProduct: 0,
    actualDate: new Date().toISOString(),
  });

  const [suggestDetails, setSuggestDetails] = useState(null);
  const [npl, setNpl] = useState('');
  const [orderPlanValue, setOrderPlanValue] = useState(null);
  const [orderPlanSearch, setOrderPlanSearch] = useState('');
  const { orderPlans } = useSelector((state) => state.orderPlanReducer);
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
    populate: 'orderId',
    orderPlanStatus: [
      constant.ORDER_PLAN_STATUS.Accept,
      constant.ORDER_PLAN_STATUS.Accept,
    ],
  });
  const [disableOrderPlan, setDisableOrderPlan] = useState(false);
  const [focused, setFocused] = useState({
    orderPlanId: false,
  });

  const handleGetOrderPlans = () => {
    if (orderPlanSearch === '') {
      dispatch(
        orderPlanActions.getOrderPlans(
          queryString.stringify(
            { ...query, orderIds: [nplOrder?.orderId?.id??orderValue.value] },
            { arrayFormat: 'bracket' }
          )
        )
      );
    } else {
      dispatch(
        orderPlanActions.getOrderPlans(
          queryString.stringify(
            {
              ...query,
              name: orderPlanSearch,
              orderIds: [nplOrder?.orderId?.id??orderValue.value],
            },
            { arrayFormat: 'bracket' }
          )
        )
      );
    }
  };
  useEffect(() => {
    if (!isFormAdd) {
      setNplOrderInfo({
        name: nplOrder?.name,
        code: nplOrder?.code,
        orderPlanId: nplOrder?.orderPlanId?.id,
        totalProduct: nplOrder?.totalProduct,
        actualDate: nplOrder?.actualDate ?? null,
      });
      setOrderPlanValue({
        label: nplOrder?.orderPlanId?.name,
        value: nplOrder?.orderPlanId?.id,
      });
      setSuggestDetails(nplOrder?.suggestDetails);
    } else {
      setSuggestDetails([]);
      setDisableOrderPlan(false);
    }
  }, [nplOrder]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetOrderPlans();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [orderPlanSearch,]);

  const onSubmit = (values, actions) => {
    if (isFormAdd) {
      dispatch(
        nplOrderActions.createNplOrder(
          {
            orderId: nplOrder?.orderId?.id??orderValue.value,
            orderPlanId: values.orderPlanId,
            code: values.code,
            name: values.name,
            suggestDate: moment(values.suggestDate).format('MM/DD/YYYY'),
            actualDate: values.actualDate,
          },
          {
            success: (data) => {
              handleGetNplOrders();
              setNpl(data);
              setSuggestDetails(data?.suggestDetails);
              setNplOrderInfo({
                name: data?.name,
                code: data?.code,
                orderPlanId: data?.orderPlanId?.id,
                totalProduct: data?.totalProduct,
                actualDate: data?.actualDate ?? null,
              });
              setDisableOrderPlan(true);
              notify(
                notificationAlertRef,
                'success',
                'Thông báo',
                `Đề xuất NPL thành công!`
              );
            },
            failed: (mess) => {
              notify(
                notificationAlertRef,
                'danger',
                'Thông báo',
                `Đề xuất NPL thất bại. Lỗi: ${mess}!`
              );
            },
          }
        )
      );
    } else {
      dispatch(
        nplOrderActions.updateNplOrder(
          {
            orderId: nplOrder?.orderId?.id??orderValue.value,
            orderPlanId: values.orderPlanId,
            code: values.code,
            name: values.name,
            suggestDate: nplOrder.suggestDate,
            actualDate: values.actualDate,
          },
          nplOrder.id,
          {
            success: (data) => {
              setSuggestDetails(data?.suggestDetails);
              handleGetNplOrders();
              notify(
                notificationAlertRef,
                'success',
                'Thông báo',
                `Cập nhật đề xuất NPL thành công!`
              );
            },
            failed: (mess) => {
              notify(
                notificationAlertRef,
                'danger',
                'Thông báo',
                `Cập nhật đề xuất NPL thất bại. Lỗi: ${mess}!`
              );
            },
          }
        )
      );
    }
  };

  const clearData = () => {
    setNplOrderInfo({
      name: '',
      code: '',
      orderPlanId: '',
      actualDate: null,
    });
    setOrderPlanValue({});
    setSuggestDetails([]);
  };

  const handleExportSuggest = () => {
    dispatch(
      exportActions.exportSuggest(npl.id, queryString.stringify({}), {
        success: (data) => {
          const filename = `MAU_NPL_${npl.code}.xlsx`;
          FileDownload(data, filename);
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Xuất mẫu nguyên phụ liệu thành công!`
          );
          handleGetNplOrders();
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Xuất mẫu nguyên phụ liệu thất bại. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  return (
    <>
      <Modal
        size="lg"
        isOpen={open}
        toggle={() => {
          setFormModal(false);
        }}
      >
        <Formik
          initialValues={nplOrderInfo}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={
            isFormAdd ? nplOrderSchemaCreate : nplOrderSchemaUpdate
          }
        >
          {({
            values,
            setFieldValue,
            handleSubmit,
            errors,
            touched,
            resetForm,
            handleBlur,
          }) => {
            return (
              <>
                <Card className="mb-0 pb-3">
                  <CardHeader className=" modal-header">
                    <p className="h2 text-uppercase">Đề xuất nguyên phụ liệu</p>
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => setFormModal(false)}
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                  </CardHeader>
                  <CardBody>
                    <Row className="mb-3">
                      {!isFormAdd && (
                        <>
                          <Col xs={3}>
                            <p className="h3 text-sm font-weight-500">
                              Người đề xuất
                            </p>
                          </Col>
                          <Col xs={9}>{nplOrder.createdBy.name}</Col>
                        </>
                      )}
                    </Row>
                    <Row className="mb-3">
                      <Col xs={3}>
                        <p className="h3 text-sm font-weight-500">
                          Ngày đề xuất
                        </p>
                      </Col>
                      <Col xs={9}>
                        {moment(nplOrder.suggestDate).format('DD/MM/YYYY')}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={3}>
                        <p className="h3 text-sm font-weight-500">
                          Mã đề xuất
                          <RequireCustom />
                        </p>
                      </Col>
                      <Col xs={9}>
                        <InputCustom
                          className="max-height-input-custom"
                          style={{ maxWidth: 150 }}
                          type="text"
                          name="code"
                          id="code"
                          placeholder="Nhập mã đề xuất"
                          invalid={errors.code && touched.code}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue('code', e.target.value);
                          }}
                          value={values.code}
                          messageInvalid={errors.code}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={3}>
                        <p className="h3 text-sm font-weight-500">
                          Tên đề xuất
                          <RequireCustom />
                        </p>
                      </Col>
                      <Col xs={9}>
                        <InputCustom
                          className="max-height-input-custom"
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Nhập tên đề xuất"
                          invalid={errors.name && touched.name}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue('name', e.target.value);
                          }}
                          value={values.name}
                          messageInvalid={errors.name}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={3}>
                        <p className="h3 text-sm font-weight-500">
                          Kế hoạch SX
                          <RequireCustom />
                        </p>
                      </Col>
                      <Col xs={9}>
                        <Select
                          placeholder="Lựa chọn"
                          isDisabled={!isFormAdd || disableOrderPlan}
                          isClearable={true}
                          value={orderPlanValue}
                          onChange={(e) => {
                            setOrderPlanValue(e);
                            setFieldValue('orderPlanId', e ? e.value : '');
                          }}
                          options={orderPlans.results
                            .map(
                              (item) =>
                                !_.isEmpty(item.orderId) && {
                                  value: item?.id,
                                  label: item?.name,
                                }
                            )
                            .filter((item) => item !== false)}
                          onInputChange={(value) => {
                            setOrderPlanSearch(value);
                          }}
                          onFocus={() => {
                            setFocused({
                              ...focused,
                              orderPlanId: true,
                            });
                          }}
                        />
                        {errors.orderPlanId && focused.orderPlanId && (
                          <Error messageInvalid={errors.orderPlanId} />
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={3}>
                        <p className="h3 text-sm font-weight-500">
                          Ngày mua thực tế
                        </p>
                      </Col>
                      <Col xs={3}>
                        <DatePicker
                          dateFormat="DD/MM/YYYY"
                          calendarPlacement="bottom"
                          value={values.actualDate}
                          onChange={(v, f) => {
                            setFieldValue('actualDate', v);
                          }}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                  <div className="d-flex justify-content-center">
                    <Button
                      onClick={() => {
                        clearData();
                        setFormModal(false);
                      }}
                    >
                      Đóng
                    </Button>

                    <Button
                      color="primary"
                      // disabled={disableOrderPlan}
                      onClick={() => {
                        setFocused({
                          orderPlanId: true,
                        });
                        if (disableOrderPlan) {
                          handleExportSuggest();
                        } else {
                          handleSubmit();
                        }
                      }}
                    >
                      {disableOrderPlan
                        ? 'Xuất mẫu nguyên phụ liệu'
                        : isFormAdd
                        ? 'Thêm mới'
                        : 'Lưu lại'}
                    </Button>
                  </div>
                </Card>
              </>
            );
          }}
        </Formik>
        {suggestDetails !== null && (
          <Card style={{ boxShadow: 'none' }} className="mb-0 pb-5">
            <CardBody>
              Danh sách nguyên phụ liệu
              <Table>
                <thead>
                  <tr>
                    <td>STT</td>
                    <td>Tên vật tư</td>
                    <td className="text-center">Đơn vị tính</td>
                    <td className="text-center">Số lượng SP</td>
                    <td className="text-center">Số lượng NPL</td>
                    <td className="text-center">Tồn kho</td>
                    <td className="text-center">SL đặt mua</td>
                  </tr>
                </thead>
                <tbody>
                  {suggestDetails.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item?.materialId?.name}</td>
                        <td className="text-center">
                          {item?.materialId?.unitId?.name}
                        </td>
                        <td className="text-center">
                          {nplOrderInfo.totalProduct}
                        </td>
                        <td className="text-center">
                          {item?.totalNeed.toFixed(2)}
                        </td>
                        <td className="text-center">
                          {item?.remainAmount.toFixed(2)}
                        </td>
                        <td className="text-center">
                          {item?.needBuy.toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        )}
      </Modal>
    </>
  );
};

export default FormNplOrder;
